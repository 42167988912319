import { navigate } from "gatsby"
import jwt from "jwt-decode"
import React, { useEffect, useState } from "react"
import Header from "../components/organisms/header/header"
import HomeContent from "../components/organisms/home-content/home-content"
import SEO from "../components/seo"
import Layout from "../components/templates/layout/layout"
import "../styles/index.scss"
import userSessionStorage from "../services/userSessionStorage"

const IndexPage = () => {

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const session = new userSessionStorage()

  useEffect(() => {
      if (window.location.href.indexOf("access_token") !== -1) {
        // loggin Familia
        let hash = window.location.hash.substr(1).split("&")

        let token = hash[0].split("=")[1]
        const user = jwt(token) // decode your token here

        let userSession = {
          username: user,
          rol: "admin",
          // rol: result.getAccessToken().payload["cognito:groups"][0],
          // accessToken: result.getAccessToken().getJwtToken(),
          // idToken: result.getIdToken().getJwtToken(),
          // refreshToken: result.getRefreshToken().token,
        }

        sessionStorage.setItem("userSession", JSON.stringify(userSession))
        navigate("/")
      }


      if (sessionStorage.getItem("userSession") !== null || session.getToken() !== null) {
        setIsLoggedIn(true)
        // validar token familia
        if (sessionStorage.getItem("userSession") !== null) {
          let tokenExp = JSON.parse(sessionStorage.getItem("userSession"))?.username?.exp

          let dateToken = new Date(0) // The 0 there is the key, which sets the date to the epoch
          dateToken.setUTCSeconds(tokenExp)

          if (!(dateToken instanceof Date && !isNaN(dateToken))) {
            sessionStorage.removeItem("userSession")
            window.location.href = "https://fam-adf-pdn.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=adfs&redirect_uri=https://bancoimagenes.grupofamilia.com/&response_type=TOKEN&client_id=hbpr775hc2cu86hpivokcn8kr&scope=email openid"
          }

          let actualDate = new Date()
          if (dateToken < actualDate) {
            sessionStorage.removeItem("userSession")
            window.location.href = "https://fam-adf-pdn.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=adfs&redirect_uri=https://bancoimagenes.grupofamilia.com/&response_type=TOKEN&client_id=hbpr775hc2cu86hpivokcn8kr&scope=email openid"
          }

        }
      } else {
        setIsLoggedIn(false)
        navigate("/login")
        // window.location.href = "https://fam-adf-pdn.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=adfs&redirect_uri=https://bancoimagenes.grupofamilia.com/&response_type=TOKEN&client_id=hbpr775hc2cu86hpivokcn8kr&scope=email openid";
      }
    },
    [
      /* sessionStorage.getItem("userSession") */
    ],
  )

  return (
    <>
      <SEO lang="es" title="Home" />
      {!isLoggedIn ? (
        <></>
      ) : (
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Header mode={"max"} />
          <Layout>
            <HomeContent />
          </Layout>
        </div>
      )}
    </>
  )
}

export default IndexPage
