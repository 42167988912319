import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import Grid from "../../molecules/grid/grid"

import {
  getProducts,
  getQueryResult,
  /*  getNewest, */ getActivities,
} from "../../../services/CMS"

import "./home-content.scss"

export default () => {
  const [productData, setProductData] = useState({})
  const [newestData, setNewestData] = useState({})
  const [activityData, setActivityData] = useState({})

  useEffect(() => {
    const getData = async () => {
      const res2 = await getQueryResult({ limit: 8, page: 1 })
      console.info(res2)
      setNewestData(res2)
      const res3 = await getActivities({ limit: 4, page: 1 })
      console.info(res3)
      setActivityData(res3)
      const res1 = await getProducts({ limit: 4, page: 1 })
      console.info(res1)
      setProductData(res1)
    }

    getData()

    return () => {}
  }, [])

  return (
    <div className="home-content-container">
      <div className="home-content-content">
        <div className="home-content-item">
          <div className="home-content-item-header">
            <h2>LO MÁS RECIENTE</h2>
            {newestData.total > 8 && (
              <span onClick={() => navigate("/buscar/todos")}>Ver todos</span>
            )}
          </div>
          <Grid data={newestData.items || []} />
        </div>
        <div className="home-content-item">
          <div className="home-content-item-header">
            <h2>ACTIVIDADES DEL MES</h2>
            {activityData.total > 4 && (
              <span onClick={() => navigate("/buscar/actividades")}>
                Ver todos
              </span>
            )}
          </div>
          {<Grid data={activityData.items || []} />}
        </div>
        <div className="home-content-item">
          <div className="home-content-item-header">
            <h2>PRODUCTO</h2>
            {productData.total > 4 && (
              <span onClick={() => navigate("/buscar/productos")}>
                Ver todos
              </span>
            )}
          </div>
          <Grid data={productData.items || []} />
        </div>
      </div>
    </div>
  )
}
